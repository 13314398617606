import "../styles/buttons.scss"

import React from 'react';

interface ButtonProps {
    text: string,
    type: string,
    disabled?: boolean,
    action?: Function,
    icon?: string,
    styles?: {}
}

const Button: React.FC<ButtonProps> = (
    {
        text,
        type,
        disabled,
        action,
        icon,
        styles
    }
) => {
    const handleClick = () => {
        if (action)
            action();
    }

    return (
        <button
            className={`btn ${type}-btn`}
            onClick={handleClick}
            disabled={disabled}
            style={styles}
        >
            {icon ? <img src={icon} alt=""/> : ""}
            {text}
        </button>
    )
}

export default Button;
