import "../styles/resetpassword.scss";

import LogoIcon from "../components/LogoIcon";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import checkedIcon from "../assets/checked.png";
import failIcon from "../assets/cancel.png";
import LoadingSpinner from "../components/LoadingSpinner";

const resetPasswordUrl =
  "https://api.shopwaremobilemanager.com/api/login/reset-password";

const passwordValidator = require("password-validator");
const schema = new passwordValidator();

schema
  .is()
  .min(8)
  .is()
  .max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(2)
  .has()
  .not()
  .spaces();

export default function ResetPassword() {
  const { userHash, resetHash } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = useState<string>("");
  const [repeatePassword, setRepeatePassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [showResponse, setShowResponse] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userHash || !resetHash) navigate("/");
  });

  const validatePassword = () => {
    if (password !== repeatePassword) {
      setErrors(["The passwords you enter are different"]);
      return;
    }

    let errors = schema.validate(password, { details: true });

    if (errors.length) {
      const messages = errors.map((e: { message: string }) => e.message);
      setErrors(messages);
    } else {
      setNewPassword(userHash, resetHash, password);
    }
  };

  const setNewPassword = async (
    userHash?: string,
    resetHash?: string,
    newPassword?: string
  ) => {
    if (!userHash || !resetHash || !newPassword) return;

    setIsLoading(true);

    const formData = new FormData();

    formData.append("userHash", userHash ?? "");
    formData.append("resetHash", resetHash ?? "");
    formData.append("newPassword", newPassword ?? "");

    try {
      const response = await fetch(resetPasswordUrl, {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        console.log(response);
        setShowResponse(true);
        setResetSuccess(true);
        setIsLoading(false);
      } else {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
    } catch (error) {
      setShowResponse(true);
      setResetSuccess(false);
      setIsLoading(false);

      console.warn("Fetch error:", error);
    }
  };

  return (
    <div className="page resetPasswordPage">
      {isLoading ? <LoadingScreen /> : null}
      {showResponse ? (
        <ResponseView success={resetSuccess} />
      ) : (
        <div className="resetPassword">
          <LogoIcon size={"xl"} />
          <span className="resetPassword-title">Reset password</span>
          <TextInput
            type={"password"}
            placeholder={"New password"}
            action={setPassword}
            styles={{ width: "100%" }}
          />
          <TextInput
            type={"password"}
            placeholder={"Repeat password"}
            action={setRepeatePassword}
            styles={{ width: "100%" }}
          />
          <div className="resetPassword-error">
            {errors.map((e, key) => (
              <p key={key}>{e}</p>
            ))}
          </div>
          <Button
            text="Reset password"
            type="primary"
            action={validatePassword}
            styles={{
              width: "100%",
              marginTop: "20px",
            }}
            disabled={!password || !repeatePassword}
          />
        </div>
      )}
    </div>
  );
}

const ResponseView = ({ success }: { success: boolean }) => {
  return (
    <div className="successfullView">
      <img src={success ? checkedIcon : failIcon} />
      <h2>
        {" "}
        {success ? "Password changed successfull" : "Something went wrong"}
      </h2>
    </div>
  );
};

const LoadingScreen = () => {
  return (
    <div className="loading-container">
      <LoadingSpinner />
    </div>
  );
};
