import "../styles/logoIcon.scss";
import logo from "../assets/icon.png";

const LogoIcon : React.FC<{size: "sm"|"md"|"xl"}> = ({size})=>{

  return (
          <img src={logo} className={"logo-icon " + size}/>
  )
}

export default LogoIcon
