import React from 'react';
import '../styles/LoadingSpinner.scss';

interface LoadingSpinnerProps {
    size?: 'small' | 'medium' | 'large';
    color?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 'medium', color = '#000' }) => {
    return (
        <div className={`spinner spinner--${size}`} style={{ borderColor: `${color} transparent transparent transparent` }}>
            <div className="spinner__circle"></div>
        </div>
    );
};

export default LoadingSpinner;