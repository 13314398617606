import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import ActivateAccount from "./components/ActivateAccount";
import LogoIcon from "./components/LogoIcon";
import ResetPassword from "./components/ResetPassword";

const App: React.FC = () => {
  return (
        <Router>
          <Routes>
            <Route path="/" element={<Welcome/>}/>
            <Route path="/reset-password/:userHash?/:resetHash?" element={<ResetPassword/>}/>
            <Route path="/activate-account/:userHash?/:activateHash?" element={<ActivateAccount/>}/>
          </Routes>
        </Router>
  );
};

export default App;

const Welcome = ()=>{
  return(
      <div className="page" style={{display: "grid", placeItems: "center", minHeight: "100vh"}}>
        <LogoIcon size={"xl"}/>
      </div>
  )
}
