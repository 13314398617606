import "../styles/textInput.scss"

import React, {useState} from 'react';

interface TextInputProps {
    type: string,
    placeholder: string,
    disabled?: boolean,
    action?: (value: string) => void,
    styles?: {}
}

const TextInput: React.FC<TextInputProps> = (
    {
        type,
        placeholder,
        disabled,
        action,
        styles
    }
) => {
    const [inputValue, setValue] = useState<string>("")

    const handleChange = (value: string) => {
        setValue(value);
        if (action) {
            action(value);
        }
    }

    return (
        <div className={"input-group"}>
            <label style={{opacity: inputValue ? '1' : '0'}}>{placeholder}</label>
            <input
                className={"text-input"}
                type={type}
                placeholder={placeholder}
                value={inputValue}
                onChange={(e) => handleChange(e.target.value)}
                disabled={disabled ? true : false}
                style={styles}
            />
        </div>
    )
}

export default TextInput;
