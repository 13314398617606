import checkedIcon from "../assets/checked.png";
import failIcon from "../assets/cancel.png";
import LoadingSpinner from "./LoadingSpinner";
import { useEffect, useState } from "react";
import LogoIcon from "./LogoIcon";
import { useNavigate, useParams } from "react-router-dom";

const activateAccountUrl =
  "https://api.shopwaremobilemanager.com/api/login/activate";

export default function ActivateAccount() {
  const navigate = useNavigate();
  const { userHash, activateHash } = useParams();
  const [activateSuccess, setActivateSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    if (!userHash || !activateHash) navigate("/");

    console.log("useEffect");

    activateAccount();
  }, []);

  const activateAccount = async () => {
    if (!userHash || !activateHash) return;

    const formData = new FormData();

    formData.append("hash", userHash ?? "");
    formData.append("verificationCode", activateHash ?? "");

    await fetch(activateAccountUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          setActivateSuccess(true);
        } else {
          setActivateSuccess(false);
        }
        return response.json();
      })
      .then((json) => {
        setTimeout(() => {
          setIsLoading(false);
          setResponseMessage(json.message);
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setResponseMessage(error.message);
          setIsLoading(false);
        }, 2000);
        console.warn("Fetch error:", error);
      });
  };

  return (
    <div
      className="page activateAccountPage"
      style={{
        display: "grid",
        placeItems: "center",
        minHeight: "100vh",
      }}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <ResponseView success={activateSuccess} message={responseMessage} />
      )}
    </div>
  );
}

const ResponseView = ({
  success,
  message,
}: {
  success: boolean;
  message: string;
}) => {
  return (
    <div className="successfullView">
      <img src={success ? checkedIcon : failIcon} />
      <h2>
        {success
          ? "Your account has been successfully activated"
          : "Something went wrong"}
      </h2>
      <h2>{message}</h2>
    </div>
  );
};

const LoadingScreen = () => {
  return (
    <div className="loading-container loading-container-transparent">
      <LogoIcon size={"xl"} />
      <LoadingSpinner />
      <h2 style={{ marginTop: "20px" }}>Account acivating</h2>
    </div>
  );
};
